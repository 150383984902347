<template id="playerheader">
  <b-col cols="6" class="m-0 p-0 pb-2">
    <b-card
      v-if="player"
      :class="
        'm-1 h-100 ' +
        (player.giocatore && player.giocatore.sfondo ? 'card-bg-opacity' : '')
      "
    >
      <b-row>
        <b-col cols="1">
          <b-row v-if="stats.length > 0">
            <b-col
              v-for="skill in standard_skills"
              cols="12"
              class="pt-1 text-center"
              :key="'st_' + skill.id"
            >
              <div
                class="round_stats"
                :style="
                  'background:' +
                  getBgStat(skill.id, getValueStat(skill.id)) +
                  ';' +
                  (getValueStat(skill.id).length < 4 ? 'font-size: .8rem;' : '')
                "
                :title="skill.label"
              >
                {{ getValueStat(skill.id) }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" class="text-center p-3">
          <avatar
            :avatar="
              player.giocatore
                ? player.giocatore.avatar_url
                : '/assets/images/profile.png'
            "
          />
          <div style="font-size: 0.7rem" class="my-2">
            <span class="text-muted">Team:</span>
            {{ team ? team.name : "" }}
          </div>
          <b-img
            v-if="team"
            :src="
              team.squadra ? team.squadra.logo_url : '/assets/images/team.png'
            "
            height="60"
          />
        </b-col>

        <b-col>
          <b-row align-v="center">
            <b-col cols="4" class="text-muted"> Surname </b-col>
            <b-col
              cols="8"
              class="font-weight-bold pl-4"
              style="font-size: 1.1rem"
            >
              {{
                player.giocatore ? player.giocatore.cognome : player.lastName
              }}
            </b-col>
            <b-col cols="4" class="text-muted"> Name </b-col>
            <b-col
              cols="8"
              class="font-weight-bold pl-4"
              style="font-size: 1.1rem"
            >
              {{ player.giocatore ? player.giocatore.nome : player.firstName }}
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Role
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{ player.ruolo ? player.ruolo.ruolo : "" }}
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Height
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{ player.giocatore ? player.giocatore.altezza : "N/D" }}
              cm
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Weight
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{ player.giocatore ? player.giocatore.peso : "N/D" }}
              kg
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Foot
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{ player.giocatore ? player.giocatore.label_piede : "N/D" }}
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Birth date
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{
                player.giocatore
                  ? formatDate(player.giocatore.data_nascita)
                  : formatDate(player.birth_date)
              }}
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Birth country
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{
                player.giocatore && player.giocatore.paese_nascita
                  ? player.giocatore.paese_nascita.paese
                  : ""
              }}
              <flag
                :country="
                  player.giocatore && player.giocatore.paese_nascita
                    ? player.giocatore.paese_nascita.sigla
                    : ''
                "
                :size="16"
                class="ml-1"
                title="Country of birth"
              ></flag>
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Passport
            </b-col>
            <b-col
              cols="8"
              class="pl-4"
              style="font-size: 0.7rem; border-left: 1px solid #adb5bd"
            >
              {{
                player.giocatore && player.giocatore.passaporti.length > 0
                  ? player.giocatore.passaporti[0].paese
                  : ""
              }}
              <flag
                :country="
                  player.giocatore && player.giocatore.passaporti.length > 0
                    ? player.giocatore.passaporti[0].sigla
                    : ''
                "
                :size="16"
                class="ml-1"
                title="Country of birth"
              ></flag>
            </b-col>
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Agent
            </b-col>
            <b-col cols="8" class="pl-4" style="font-size: 0.7rem">
              {{
                player.giocatore && player.giocatore.agente
                  ? player.giocatore.agente.nome_completo
                  : ""
              }}
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="4" class="text-muted" style="font-size: 0.7rem">
              Evaluations:
              <span style="color: green; font-weight: bold; font-size: 1rem">
                {{
                  player.giocatore && player.giocatore.osservato
                    ? player.giocatore.osservato.valutazioni.length
                    : "0"
                }}
              </span>
            </b-col>

            <b-col>
              <b-popover
                v-if="
                  player.giocatore &&
                  player.giocatore.osservato &&
                  player.giocatore.osservato.valutazioni.length > 0
                "
                target="popover-target"
                triggers="hover"
                placement="top"
              >
                <b-row
                  align-v="center"
                  v-for="val in player.giocatore.osservato.valutazioni"
                  :key="val.id"
                >
                  <b-col>
                    {{ val.created_at | formatDate }}
                  </b-col>
                  <b-col>
                    {{ val.utente.nickname }}
                  </b-col>
                  <b-col>
                    <div class="py-1">
                      <grad1 class="grad" v-if="val.gradimento_id == 1" />
                      <grad2 class="grad" v-if="val.gradimento_id == 2" />
                      <grad3 class="grad" v-if="val.gradimento_id == 3" />
                      <grad4 class="grad" v-if="val.gradimento_id == 4" />
                      <grad5 class="grad" v-if="val.gradimento_id == 5" />
                    </div>
                  </b-col>
                </b-row>
              </b-popover>

              <div id="popover-target">
                <b-row>
                  <b-col class="p-0 text-center"><grad1 class="grad" /></b-col>
                  <b-col class="p-0 text-center"><grad2 class="grad" /></b-col>
                  <b-col class="p-0 text-center"><grad3 class="grad" /></b-col>
                  <b-col class="p-0 text-center"><grad4 class="grad" /></b-col>
                  <b-col class="p-0 text-center"><grad5 class="grad" /></b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col class="p-0 text-center"
                    ><b-badge variant="bg-1" :style="grad1_style">{{
                      grad1_count
                    }}</b-badge>
                  </b-col>
                  <b-col class="p-0 text-center"
                    ><b-badge variant="bg-2" :style="grad2_style">{{
                      grad2_count
                    }}</b-badge>
                  </b-col>
                  <b-col class="p-0 text-center"
                    ><b-badge variant="bg-3" :style="grad3_style">{{
                      grad3_count
                    }}</b-badge></b-col
                  >
                  <b-col class="p-0 text-center"
                    ><b-badge variant="bg-4" :style="grad4_style">{{
                      grad4_count
                    }}</b-badge></b-col
                  >
                  <b-col class="p-0 text-center"
                    ><b-badge variant="bg-5" :style="grad5_style">{{
                      grad5_count
                    }}</b-badge></b-col
                  >
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="margin-top: -35px">
        <b-col cols="4" class="ml-4">
          <imc
            :peso="player.giocatore ? player.giocatore.peso : 0"
            :altezza="player.giocatore ? player.giocatore.altezza : 0"
            :eta="
              age(
                player.giocatore
                  ? player.giocatore.data_nascita
                  : player.birthDate
              )
            "
          ></imc>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import moment from "moment";
import Avatar from "@/components/Avatar.vue";
import Flag from "@/components/Flag.vue";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";
import Imc from "@/components/Imc.vue";
//import HorizontalBarSkill from "@/components/charts/HorizontalBarSkill.vue";
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    team: {
      type: Object,
      required: false,
    },
    stats: {
      type: Array,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
    range: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      opacity_value: [1, 0.8, 0.7, 0.6, 0.5],
    };
  },

  components: {
    Avatar,
    Flag,
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
    Imc,
    //    HorizontalBarSkill,
  },

  computed: {
    standard_skills: function () {
      return this.skills.filter((item) => item.standard === 1);
    },

    grad1_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 1
        ).length;
      }
      return 0;
    },

    grad2_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 2
        ).length;
      }
      return 0;
    },

    grad3_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 3
        ).length;
      }
      return 0;
    },

    grad4_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 4
        ).length;
      }
      return 0;
    },

    grad5_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 5
        ).length;
      }
      return 0;
    },
    opacity: function () {
      var count_grad = [
        this.grad1_count,
        this.grad2_count,
        this.grad3_count,
        this.grad4_count,
        this.grad5_count,
      ];
      var array_index = [0, 1, 2, 3, 4];
      var count = 0;

      var opacity = [
        { index: 0, grad: count_grad[0] },
        { index: 1, grad: count_grad[1] },
        { index: 2, grad: count_grad[2] },
        { index: 3, grad: count_grad[3] },
        { index: 4, grad: count_grad[4] },
      ];

      opacity.sort(function (a, b) {
        return b.grad > a.grad;
      });

      var array_index = [];
      for (var index in opacity) {
        array_index.push(opacity[index].index);
      }
      return array_index;
    },
    grad1_style: function () {
      if (this.grad1_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad2_style: function () {
      if (this.grad2_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad3_style: function () {
      if (this.grad3_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad4_style: function () {
      if (this.grad4_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad5_style: function () {
      if (this.grad5_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
  },

  methods: {
    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("MM/DD/YYYY");
      }
      return "";
    },

    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },

    getBgStat(skill_id, value) {
      if (!isNaN(value) && Object.keys(this.range).length > 0) {
        value = parseFloat(value);
        var skill = this.getSkillById(skill_id);
        var id = skill_id;
        var array_range = this.range[id];
        var count_array = array_range.length;
        var interval = count_array > 10 ? Math.floor(count_array / 10) : 1;
        var index = 0;
        if (skill.reverse) {
          for (var i = count_array - 1; i >= 0; i -= interval) {
            var to = i - interval;
            if (i - interval < 0) {
              to = 0;
            }
            if (index == 0 && value < parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value > parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value >= parseFloat(array_range[i].skill_value) &&
              value <= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        } else {
          for (var i = 0; i <= count_array - 1; i += interval) {
            var to = i + interval;
            if (i + interval > count_array - 1) {
              to = count_array - 1;
            }
            if (index == 0 && value > parseFloat(array_range[i].skill_value)) {
              return this.bg_opta[index];
            }
            if (index == 9 && value < parseFloat(array_range[to].skill_value)) {
              return this.bg_opta[index];
            }
            if (
              value <= parseFloat(array_range[i].skill_value) &&
              value >= parseFloat(array_range[to].skill_value)
            ) {
              return this.bg_opta[index > 9 ? 9 : index];
            }
            index++;
          }
        }
      }
      return "#FFFFFF";
    },

    getValueStat(skill_id) {
      var skill = this.getSkillById(skill_id);
      var stats = this.stats;
      var id = skill_id;
      for (var i in stats) {
        var item = stats[i];
        if (item.skill_id == id) {
          return (
            "" +
            (this.isFloat(item.skill_value)
              ? Math.round(item.skill_value * 100) / 100
              : item.skill_value)
          );
        }
      }
      return "N/D";
    },

    isFloat(n) {
      return n != "" && !isNaN(n) && Math.round(n) != n;
    },

    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return 0;
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YY");
      }
      return "";
    },
  },
};
</script>
