<template id="similar_players">
  <b-table-simple small responsive>
    <b-thead>
      <b-tr>
        <b-th></b-th>
        <b-th>Players</b-th>
        <b-th class="text-center">Distance</b-th>
        <b-th
          class="text-center"
          v-for="skill_id in skill_ids"
          :key="skill_id"
          >{{ getSkillById(skill_id).label }}</b-th
        >
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="(player, index) in players" :key="player.player_id">
        <b-td style="vertical-align: middle"
          ><b-img :src="player.avatar" height="30"></b-img
        ></b-td>
        <b-td style="vertical-align: middle"
          ><b-link
            v-if="index > 0"
            :href="'/scouting/view/player/' + player.player_id"
            target="_blank"
            >{{ player.name }}</b-link
          >
          <div v-else style="color: #ff0000">{{ player.name }}</div>
        </b-td>
        <b-td style="vertical-align: middle" class="text-center">{{
          player.distance
        }}</b-td>
        <b-td
          class="text-center"
          v-for="skill_id in skill_ids"
          :key="skill_id"
          style="vertical-align: middle"
        >
          <div
            v-if="player.skills[skill_id]"
            :style="'background:' + player.skills[skill_id].color"
            class="box-small"
          >
            {{ player.skills[skill_id].value }}
          </div>
          <div v-else class="text-center">N/D</div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  props: {
    skills: {
      type: Array,
      required: true,
    },
    skill_ids: {
      type: Array,
      required: true,
    },
    players: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {};
  },

  methods: {
    getSkillById(id) {
      return this.skills.find((skill) => skill.id == id);
    },
  },
};
</script>
